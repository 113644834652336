import menu from "../../images/menu.png"
import cart from "../../images/cart.png"
import logo from "../../images/logo1.png"
import CartContainer from "../cart/CartContainer"
import { Menu } from "@headlessui/react"


const Header = ({ showCart, setShowCart }) => {


    const toggleCart = () => {
        setShowCart(prevShowCart => !prevShowCart)
    }

    return (
        <header className='fixed w-full bg-zinc-900 top-0 shadow-lg z-10'>
            <nav className='flex justify-between p-2'>
                <div className="flex items-center justify-between w-full mx-4">
                    <Menu as='div' className='flex flex-col'>
                        <Menu.Button className='text-white flex '>
                            <img src={menu} className='invert w-6'></img>
                        </Menu.Button>
                        <Menu.Items className='bg-zinc-900 absolute flex flex-col my-8 -mx-2 w-40 rounded-md'>
                            <Menu.Item
                                as='a'
                                className='text-white p-2'
                                href="https://moonsarg.ar/envios"
                            >
                                Pago y envíos
                            </Menu.Item>
                            <Menu.Item
                                as='a'
                                className='text-white p-2'
                                href="https://moonsarg.ar/garantia"
                            >
                                Garantía
                            </Menu.Item>
                            <Menu.Item
                                as='a'
                                className='text-white p-2'
                                href="https://moonsarg.ar/about"
                            >
                                Nosotros
                            </Menu.Item>
                        </Menu.Items>
                    </Menu>

                    <a href="https://moonsarg.ar/">
                        <img className="w-20" src={logo} />
                    </a>

                    <button onClick={() => toggleCart()}>
                        <img className='w-[32px] invert' src={cart} />
                    </button>
                </div>
            </nav>

            <CartContainer show={showCart} setShow={toggleCart} />
        </header>
    )
}

export default Header