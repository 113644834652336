import { Link } from "react-router-dom"
import { useState, useEffect } from "react"
import { getFirestore, getDocs, collection } from "firebase/firestore"
import firebaseApp from "../../firebase"
import load from "../../images/tail-spin.svg"
const firestore = getFirestore(firebaseApp)


const ProductsList = ({ input }) => {
    const [relojes, setRelojes] = useState([])
    const [loading, setLoading] = useState(true)

    const searchDocs = async () => {
        let relojes = []
        const colRef = collection(firestore, `/relojes`)
        const query = await getDocs(colRef)
        relojes = query.docs.map(doc => {
            const data = doc.data()
            const id = doc.id
            return { ...data, id }
        })
        return relojes
    }

    // const filterInput = () => {
    //     return relojes.filter(reloj => reloj.nombre.toLocaleLowerCase().includes(input.toLocaleLowerCase()))
    // }

    useEffect(() => {
        const fetchRelojes = async () => {
            const response = await searchDocs()
            setRelojes(response)
        }

        fetchRelojes()
    }, [])

    return (
        <section className="flex flex-wrap m-[2.5%] justify-center sm:justify-center">
            {relojes.length ?
                relojes.map(reloj => {
                    return (
                        <Link to={`/relojes/${reloj.id}`} key={reloj.id} className=' shadow-md w-[45%] min-h-fit rounded-xl my-2 mx-auto max-w-[10rem] sm:max-w-xs'>

                            <img className='w-[50vw] h-[25vh]' src={reloj.imagen} />

                            <h2 className='font-semibold indent-2'>
                                {reloj.nombre}
                            </h2>

                            <p className='indent-2'>
                                ${reloj.precio}
                            </p>

                        </Link>
                    )
                })
                :
                <img src={load} className='w-[20vw]' />
            }
        </section>
    )
}

export default ProductsList