import Swal from "sweetalert2"
import back from "../../images/back.svg"
import load from "../../images/tail-spin.svg"
import { useEffect, useState } from "react"
import { Link, useLocation, useParams } from "react-router-dom"
import { getFirestore, getDoc, doc } from "firebase/firestore"
import firebaseApp from "../../firebase"
const firestore = getFirestore(firebaseApp)

const Product = () => {
    const { id } = useParams()
    const [reloj, setReloj] = useState({})
    const location = useLocation()

    const searchDoc = async (idReloj) => {
        const docRef = doc(firestore, `/relojes/${idReloj}`)
        const query = await getDoc(docRef)
        let reloj = query.data()
        return reloj
    }

    useEffect(() => {
        const fetchReloj = async () => {
            const response = await searchDoc(id)
            setReloj(response)
        }

        fetchReloj()

        window.scrollTo(0,0)
    }, [])

    const formatText = (text) => {
        let currentIndex = 0
        let words = []

        for (let i = 0; i < text.length; i++) {
            if (text[i] == '.') {
                let parse = text.slice(currentIndex, i)
                words.push(parse)
                currentIndex = i
            }
        }

        words[0] = '. ' + words[0]

        return words
    }

    const addToCart = () => {
        if (localStorage.getItem('cart')) {
            const products = JSON.parse(localStorage.getItem('cart'))
            const filterProd = products.filter(prod => prod.nombre == reloj.nombre)
            if (filterProd.length) {
                Swal.fire({
                    title: "El producto ya está en el carro!",
                    icon: "error",
                    timer: 3000,
                    timerProgressBar: "true",
                    confirmButtonColor: "#18181b",
                    iconColor: "#18181b",
                    reverseButtons: true,
                })
            } else {
                const parsedProducts = JSON.stringify([...products, { ...reloj, cantidad: 1 }])
                localStorage.setItem('cart', parsedProducts)
                Swal.fire({
                    title: "Producto agregado al carrito",
                    icon: "success",
                    timer: 2500,
                    timerProgressBar: "true",
                    confirmButtonColor: "#18181b",
                    iconColor: "#18181b",
                    reverseButtons: true,
                })
            }
        } else {
            const data = JSON.stringify([{ ...reloj, cantidad: 1 }])
            localStorage.setItem('cart', data)
            Swal.fire({
                title: "Producto agregado al carrito",
                icon: "success",
                timer: 2500,
                timerProgressBar: "true",
                confirmButtonColor: "#18181b",
                iconColor: "#18181b",
                reverseButtons: true,
            })
        }
    }

    return (
        <>
            <Link to='/'>
                <img className="m-4" src={back} />
            </Link>
            <main className={reloj.nombre ? 'p-4 h-[100vh]' : 'p-4 h-[100vh] flex justify-center'}>


                {reloj.nombre ? <section>
                    <img className=' max-h-[50vh] mx-auto' src={reloj.imagen} />

                    <h2 className='font-bold text-5xl mb-6'>
                        {reloj.nombre}
                    </h2>



                    <article className="my-4">
                        <h2 className="font-bold">
                            Descripción:
                        </h2>

                        <section>
                            {reloj.descripcion && formatText(reloj.descripcion).map(word => {
                                return (
                                    <p key={word}>
                                        {word}
                                    </p>
                                )
                            })}
                        </section>
                    </article>

                    <article className="my-4">
                        <h2 className="font-bold">
                            Color de equipo:
                        </h2>

                        <p>
                            {reloj.colorEquipo}
                        </p>
                    </article>

                    <article className="my-4">
                        <h2 className="font-bold">
                            Color de malla:
                        </h2>

                        <p>
                            {reloj.colorMaya}
                        </p>
                    </article>

                    <article className="my-4">
                        <p className=' text-3xl'>
                            ${reloj.precio}
                        </p>
                        <h2 className="my-4">
                            Hasta {reloj.descuentoC}% de descuento con tu compra de 50 o más {reloj.nombre}
                        </h2>
                    </article>


                    <button
                        className=" rounded-lg bg-zinc-900 h-12 w-full text-white my-4"
                        onClick={() => { addToCart() }}
                    >
                        LO QUIERO
                    </button>
                </section> : <img src={load} className='w-[20vw]' />}
            </main >
        </>
    )
}

export default Product