import logo from "../../images/logo.png"
import firebaseApp from "../../firebase";
import {
    getAuth,
    signInWithEmailAndPassword
} from "firebase/auth"
const auth = getAuth(firebaseApp)

export default function Login() {
    const submitHandle = async (e) => {
        e.preventDefault();
        const email = e.target.email.value;
        const password = e.target.password.value;
        signInWithEmailAndPassword(auth, email, password)
    }

    return (
        <>
            <header className='fixed w-full bg-zinc-900 top-0 shadow-lg z-10'>
                <nav className='flex justify-between items-center p-2'>
                    <img className="w-[128px]" src={logo} />
                </nav>
            </header>
            <main className="mt-20 p-10 h-[50vh] flex flex-col justify-around">
                <h1 className="text-center font-bold">
                    Login
                </h1>
                <form onSubmit={submitHandle} className='flex flex-col justify-between h-[20vh]'>
                    <input
                        className='w-100 shadow-lg rounded-sm'
                        type="text"
                        id='email'
                        placeholder="Email"
                    ></input>

                    <input
                        className='w-100 shadow-lg rounded-sm '
                        type="password"
                        id='password'
                        placeholder="Contraseña"
                    ></input>

                    <button className="shadow-lg rounded-lg text-white bg-zinc-900">
                        Inicia Sesion
                    </button>
                </form>
            </main>

        </>
    )
}