import { Link } from "react-router-dom"
import logo from "../../images/logo.png"
import ListaPedidos from "./ListaPedidos"

export default function Admin () {
    return (
        <>
            <header className='fixed w-full bg-zinc-900 top-0 shadow-lg z-10'>
                <nav className='flex justify-around items-center p-2'>
                    <img className="w-[128px]" src={logo} />
                    <Link className="text-white underline underline-offset-4" to='/admin'>Pedidos</Link>
                    <Link className="text-white" to='/admin/relojes'>Relojes</Link>
                </nav>
            </header>
            <ListaPedidos />
        </>
    )
}