import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useState } from 'react';
import Home from "./components/home/Home";
import Garantia from './components/home/Garantia';
import About from './components/home/About';
import Envios from './components/home/Envios';
import Product from './components/products/Product';
import Checkout from './components/checkout/Checkout';
import Login from './components/login/Login';
import Admin from "./components/panel/Admin";
import Pedido from "./components/panel/Pedido";
import Reloj from './components/panel/Reloj';
import ListaRelojes from './components/panel/ListaRelojes';
import firebaseApp from './firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
const auth = getAuth(firebaseApp);

export default function App() {
  const [user, setUser] = useState(null)
  const [showCart, setShowCart] = useState(false)

  onAuthStateChanged(auth, (usuarioFirebase) => {
    usuarioFirebase ? setUser(usuarioFirebase) : setUser(null)
  })

  return (
      <Router>
        <Routes>
          <Route path='/' element={<Home showCart={showCart} setShowCart={setShowCart}/>} />
          <Route path='/relojes/:id' element={<Product />} />
          <Route path='/checkout' element={<Checkout />} />
          <Route path='/garantia' element={<Garantia showCart={showCart} setShowCart={setShowCart}/>} />
          <Route path='/envios' element={<Envios showCart={showCart} setShowCart={setShowCart}/>} />
          <Route path='/about' element={<About showCart={showCart} setShowCart={setShowCart}/>} />
          <Route path='/admin' element={user ? <Admin /> : <Login />} />
          <Route path='/admin/relojes' element={user ? <ListaRelojes /> : <Login />} />
          <Route path='/admin/relojes/:id' element={user ? <Reloj /> : <Login />} />
          <Route path='/admin/pedidos/:id' element={user ? <Pedido /> : <Login /> } />
        </Routes>
      </Router>
  );
}