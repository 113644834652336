import { getFirestore, getDocs, collection, query, where } from "firebase/firestore"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import firebaseApp from "../../firebase"
const firestore = getFirestore(firebaseApp)

export default function ListaPedidos () {
    const [orders, setOrders] = useState(null)

    const totalPrice = (productos) => {
        return productos.reduce((acc, producto) => acc + producto.precio * producto.cantidad, 0)
    }

    const getOrders = async () => {
        const colRef = collection(firestore, '/pedidos')
        const queryOrders = query(colRef, where('estado', '==', 'approved'))
        const querySnapshot = await getDocs(queryOrders)
        let orders = querySnapshot.docs.map(order => {
            let data = order.data()
            let id = order.id
            return {id, ...data}
        }) 
        return orders
    }

    useEffect(() => {
        const fetchOrders = async () => {
            const response = await getOrders()
            setOrders(response)
        }

        fetchOrders()
    }, [])

    return (
        <section className="mt-20 ">
            {orders && orders.map(order => {
                return (
                    <Link to={`/admin/pedidos/${order.id}`} className="flex justify-around p-4 border-b-2 my-1 text-center" key={order.id}>
                        <p>{order.fecha}</p>
                        <p>{order.cliente.name}</p>
                        <p>${totalPrice(order.productos).toFixed(2)}</p>
                    </Link>
                )
            })}
        </section>
    )
}