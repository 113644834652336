import fb from "../../images/fb.png"
import ig from "../../images/ig.png"

export default function Footer() {

    return (
        <footer className="bg-zinc-900 text-white p-4 flex flex-col items-center">

            <section className="mt-2 flex flex-col items-center">
                <h2>
                    CONTACTO
                </h2>

                <address className="flex flex-col items-center">
                    <a className="p-2 underline underline-offset-2" href="mailto:ventasmayoristas@moonsarg.ar">ventasmayoristas@moonsarg.ar</a>
                    <a className="p-2 underline underline-offset-2" href="mailto:soporte@moonsarg.ar">soporte@moonsarg.ar</a>
                </address>
            </section>

            <section>
                <h2 className="mt-2 mb-1  text-center">
                    REDES
                </h2>
                <article className="flex justify-center">
                    <a href="https://www.instagram.com/moonsarg.store"><img className="w-10 invert mx-1" src={ig} /></a>
                    <a href="https://www.facebook.com/tecnoventalaplata"><img className="w-10 invert mx-1" src={fb} /></a>
                </article>
            </section>


        </footer>
    )
}