import back from "../../images/back.svg"
import { useState, useEffect } from "react"
import axios from 'axios'
import { Link } from "react-router-dom"

const Checkout = () => {
    const [products, setProducts] = useState([])
    const [contacto, setContacto] = useState({
        name: '',
        surname: '',
        email: '',
        dni: '',
        area: '',
        telephone: '',
        postal: '',
        address: '',
        number: ''
    })

    useEffect(() => {
        if (localStorage.getItem('cart')) {
            const data = JSON.parse(localStorage.getItem('cart'))
            setProducts(data)
        } else {
            return
        }
    }, [])

    const total = () => {
        return products.reduce((acc, el) => {
            if (el.cantidad >= 50) {
                return (acc + (el.precio - (el.precio * el.descuentoC / 100)) * el.cantidad)
            } else if (el.cantidad >= 10 && el.cantidad <= 49) {
                return (acc + (el.precio - (el.precio * el.descuentoB / 100)) * el.cantidad)
            } else if (el.cantidad >= 5) {
                return (acc + (el.precio - (el.precio * el.descuentoA / 100)) * el.cantidad)
            } else {
                return (acc + el.precio * el.cantidad)
            }
        }, 0)
    }

    const handleOnChange = (e) => {
        const { name, value } = e.target

        setContacto(prevContacto => {
            return {
                ...prevContacto,
                [name]: value
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        axios.post('https://moonsarg.ar/checkout', { list: products, payer: contacto }, {
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response => {
                localStorage.setItem('cart', [])
                window.location.href = response.data
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <main>
            <h1 className="bg-zinc-900 h-12 w-full text-white flex justify-center items-center">FINALIZANDO COMPRA</h1>
            <Link to='/'>
                <img src={back} className="mx-5 my-5"/>
            </Link>
            <section className="flex flex-col items-center">
                <h2 className="px-10 pb-6 flex justify-between text-xl">
                    Total a pagar: <strong>${total().toFixed(2)}</strong>
                </h2>


                <form className="flex flex-col w-80 sm:w-[80vw]" onSubmit={handleSubmit}>
                    <label className="text-2xl mb-3"><strong>Contacto:</strong></label>
                    <input required onChange={handleOnChange} className="shadow-md border-zinc-900 border-2 rounded-md mb-2 p-1" type="text" name="name" placeholder="Nombre" id="" />
                    <input required onChange={handleOnChange} className="shadow-md border-zinc-900 border-2 rounded-md mb-2 p-1" type="text" name="surname" placeholder="Apellido" id="" />
                    <input required onChange={handleOnChange} className="shadow-md border-zinc-900 border-2 rounded-md mb-2 p-1" type="email" name="email" placeholder="Email" id="" />
                    <label className="text-2xl  mb-3"><strong>Identificación:</strong></label>
                    <input required onChange={handleOnChange} className="shadow-md border-zinc-900 border-2 rounded-md mb-2 p-1" type="number" name="dni" placeholder="DNI" id="" />
                    <label className="text-2xl  mb-3"><strong>Teléfono:</strong></label>
                    <input required onChange={handleOnChange} className="shadow-md border-zinc-900 border-2 rounded-md mb-2 p-1" type="tel" name="area" placeholder="Cod. de area" id="" />
                    <input required onChange={handleOnChange} className="shadow-md border-zinc-900 border-2 rounded-md mb-2 p-1" type="number" name="telephone" placeholder="Número" id="" />
                    <label className=" text-2xl  mb-3"><strong>Dirección:</strong></label>
                    <input required onChange={handleOnChange} className="shadow-md border-zinc-900 border-2 rounded-md mb-2 p-1" type="number" name="postal" placeholder="Cod. postal" id="" />
                    <input required onChange={handleOnChange} className="shadow-md border-zinc-900 border-2 rounded-md mb-2 p-1" type="text" name="address" placeholder="Dirección" id="" />
                    <input required onChange={handleOnChange} className="shadow-md border-zinc-900 border-2 rounded-md mb-2 p-1" type="number" name="number" placeholder="Número" id="" />
                    <input className="bg-zinc-900 h-12 w-full text-white rounded-md mb-4" type='submit' value='COMPRAR' />
                </form>
            </section>

        </main>
    )
}

export default Checkout