import React from 'react'
import Footer from './Footer'
import Header from './Header'
import Wsp from './Wsp'
import land from '../../images/land.jpg'

export default function About({showCart, setShowCart}) {
  return (
    <>
      <Header showCart={showCart} setShowCart={setShowCart}/>
      <article className='p-4 mt-24 sm:px-40'>
        <h1 className='text-center font-bold text-3xl p-4'>
          MOONS
        </h1>

        <section className='text-lg'>
          <p className='my-4'>
            Somos MOONSARG, una empresa joven que busca posicionarse en el mercado mayorista con excelentes precios y productos. Queremos ser su importador de confianza, buscando darle los recursos necesarios para su negocio.
          </p>

          <p className='my-4'>
            Somos importadores hace 25 años de todos los productos que comercializamos y distribuimos a nivel nacional, de forma directa y también con una red de distribuidores regionales que abarcan todo el país.
          </p>

          <p className='my-4'>
            Estamos para asesorarte y guiarte durante todo el proceso de compra y tambien de post venta.
            Es nuestra misión, generar clientes felices y satisfechos con el producto que adquieran y que estén a gusto con nuestro servicio de atención al cliente y con la gestión online de tu compra.
          </p>

          <img className='my-10 rounded-xl sm:w-[35vw] sm:mx-auto' src={land}></img>

        </section>
      </article>
      <Footer />
      <Wsp />
    </>
  )
}