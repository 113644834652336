import Cart from "./Cart"


const CartContainer = ({ show, setShow }) => {
    return (
        <>
            {show && <Cart set={setShow}/>}
        </>
    )
}

export default CartContainer