import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { doc, getDoc, getFirestore } from 'firebase/firestore'
import back from "../../images/back.svg"
import firebaseApp from '../../firebase'
const firestore = getFirestore(firebaseApp)

export default function Pedido() {
    const [order, setOrder] = useState(null)
    const { id } = useParams()

    const totalPrice = (productos) => {
        return productos.reduce((acc, producto) => acc + producto.precio * producto.cantidad, 0)
    }

    const getOrderById = async (id) => {
        const docRef = doc(firestore, `/pedidos/${id}`)
        const orderDoc = await getDoc(docRef)
        const data = orderDoc.data()
        return data
    }

    useEffect(() => {
        const fetchOrder = async () => {
            const response = await getOrderById(id)
            setOrder(response)
        }

        fetchOrder()
    }, [])

    return (
        <>
            {order &&
                <main className='h-[100vh]'>
                    <Link to='/admin'>
                        <img src={back}></img>
                    </Link>
                    <section className='text-center'>
                        <p className='text-xl font-bold'>{order.fecha}</p>
                    </section>

                    <section className='px-3 text-lg'>
                        <article className='text-center text-lg p-2 font-bold'>
                            <p className='underline'>
                                {order.cliente.name} {order.cliente.surname}
                            </p>
                        </article>

                        <article className='flex justify-between'>
                            <h3 className='underline underline-offset-2 flex'>
                                DNI:
                            </h3>
                            <p>
                                {order.cliente.dni}
                            </p>
                        </article>
                        <article className='flex justify-between'>

                            <h3 className='underline underline-offset-2 flex'>
                                Email:
                            </h3>
                            <p>
                                {order.cliente.email}
                            </p>
                        </article>
                        <article className='flex justify-between'>

                            <h3 className='underline underline-offset-2 flex'>
                                Cod. de area:
                            </h3>
                            <p>
                                {order.cliente.area}
                            </p>
                        </article>
                        <article className='flex justify-between'>

                            <h3 className='underline underline-offset-2 flex'>
                                Telefono:
                            </h3>
                            <p>
                                {order.cliente.telephone}
                            </p>
                        </article>
                        <article className='flex justify-between'>

                            <h3 className='underline underline-offset-2 flex'>
                                Calle:
                            </h3>
                            <p>
                                {order.cliente.address}
                            </p>
                        </article>
                        <article className='flex justify-between'>
                            <h3 className='underline underline-offset-2 flex'>
                                Numero de casa:
                            </h3>
                            <p>
                                {order.cliente.number}
                            </p>
                        </article>
                        <article className='flex justify-between'>
                            <h3 className='underline underline-offset-2 flex'>
                                Cod. postal:
                            </h3>
                            <p>
                                {order.cliente.postal}
                            </p>
                        </article>
                    </section>

                    <h2 className='text-center text-lg font-bold p-2 underline'>
                        Pedido:
                    </h2>
                    <section className='flex flex-col min-h-[45vh]'>
                        {order.productos.map(producto => {
                            return (
                                <article className='text-xl p-2 flex justify-between' key={producto.nombre}>
                                    <p className='w-1/4'>{producto.nombre}</p>
                                    <p className='w-1/4 text-right'>x{producto.cantidad}</p>
                                    <p className='w-1/2 text-right'>${producto.precio}</p>
                                </article>
                            )
                        })}


                    </section>
                    <article className='flex justify-between border-t-2 border-t-black p-4'>
                        <h2 className='text-xl font-bold'>
                            <strong>Total:</strong>
                        </h2>
                        <p className='text-right  text-xl'>
                            ${totalPrice(order.productos).toFixed(2)}
                        </p>
                    </article>
                </main>
            }
        </>
    )
}