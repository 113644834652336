import React from 'react'
import logo from "../../images/logo.png"
import { Link } from 'react-router-dom'
import { useState, useEffect } from "react"
import { getFirestore, getDocs, collection } from "firebase/firestore"
import firebaseApp from "../../firebase"
import load from "../../images/tail-spin.svg"
const firestore = getFirestore(firebaseApp)

export default function ListaRelojes() {
    const [relojes, setRelojes] = useState([])

    const searchDocs = async () => {
        let relojes = []
        const colRef = collection(firestore, `/relojes`)
        const query = await getDocs(colRef)
        relojes = query.docs.map(doc => {
            const data = doc.data()
            const id = doc.id
            return { ...data, id }
        })
        return relojes
    }

    useEffect(() => {
        const fetchRelojes = async () => {
            const response = await searchDocs()
            setRelojes(response)
        }

        fetchRelojes()
    }, [])

    return (
        <>
            <header className='fixed w-full bg-zinc-900 top-0 shadow-lg z-10'>
                <nav className='flex justify-around items-center p-2'>
                    <img className="w-[128px]" src={logo} />
                    <Link className="text-white" to='/admin'>Pedidos</Link>
                    <Link className="text-white underline underline-offset-4" to='/admin/relojes'>Relojes</Link>
                </nav>
            </header>
            <section className="flex flex-wrap mt-24 justify-center sm:justify-center">
            {relojes.length ?
                relojes.map(reloj => {
                    return (
                        <Link to={`/admin/relojes/${reloj.id}`} key={reloj.id} className=' shadow-md w-[45%] min-h-fit rounded-xl my-2 mx-auto max-w-[10rem] sm:max-w-xs'>

                            <img className='w-[50vw] h-[25vh]' src={reloj.imagen} />

                            <h2 className='font-semibold indent-2'>
                                {reloj.nombre}
                            </h2>

                            <p className='indent-2'>
                                ${reloj.precio}
                            </p>

                        </Link>
                    )
                })
                :
                <img src={load} className='w-[20vw]' />
            }
        </section>
        </>
    )
}
