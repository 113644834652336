import { Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import 'swiper/css'
import info1 from "../../images/info1.jpg"
import info2 from "../../images/info2.jpg"
import info3 from "../../images/info3.jpg"

const Featured = () => {
    return (
        <Swiper
            id="swiper"
            className='h-96 max-w-xl'
            spaceBetween={10}
            modules={[Autoplay]}
            slidesPerView={1}
            autoplay={{
                delay: 3000,
                disableOnInteraction: false
            }}
        >
            <SwiperSlide className="p-4">
                <img className='w-full h-full rounded-3xl' src={info1} ></img>
            </SwiperSlide>
            <SwiperSlide className="p-4">
                <img className="w-full h-full rounded-3xl" src={info2} ></img>
            </SwiperSlide>
            <SwiperSlide className="p-4">
                <img className="w-full h-full rounded-3xl" src={info3} ></img>
            </SwiperSlide>
        </Swiper>
    )
}

export default Featured