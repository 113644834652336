import ProductsList from "../products/ProductsList"
import Featured from "./Featured"
import Header from "./Header"
import Footer from "./Footer"
import Wsp from "./Wsp"
import correo from "../../images/correo.png"

const Home = ({showCart, setShowCart}) => {
    
    return (
        <>
            <Header 
            showCart={showCart} 
            setShowCart={setShowCart}
            />
            <main className="min-h-[60vh]">
                <section className="w-full p-4 mt-28">
                    <h1 className='text-4xl text-zinc-900 sm:text-center'>
                        Elegí tu nuevo smart watch.
                    </h1>
                </section>
                <Featured />
                <ProductsList />
                <section className="flex justify-between">
                    <aside className="px-2">
                        <h2 className="text-xl font-black leading-none text-[#162269]">Envíos</h2>
                        <h3 className="text-xl font-black text-[color:#162269]">Gratis</h3>
                    </aside>
                    <img className=" w-40" src={correo}/>
                </section>
            </main>
            <Footer />
            <Wsp showCart={showCart}/>
        </>
    )
}

export default Home