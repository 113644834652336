import React from 'react'
import Footer from './Footer'
import Header from './Header'
import Wsp from './Wsp'


export default function Garantia({showCart, setShowCart}) {
  return (
    <>
      <Header showCart={showCart} setShowCart={setShowCart}/>
      <article className='p-4 mt-24 sm:px-40'>
        <h1 className='text-center font-bold text-3xl p-4'>
          GARANTÍA
        </h1>

        <section className='text-lg'>
          <p className='my-4'>
            Dentro de nuestra misión como empresa, en MOONS tenemos la necesidad de ofrecerte como cliente una compra clara, segura y confiable.
          </p>
          <p className='my-4'>
            Para ello disponemos de una tienda online, 100% segura y testeada, utilizando como pasarela de pago MercadoPago, que te brinda la tranquilidad y seguridad necesaria a la hora de hacer tu pago online.
          </p>
          <p className='my-4'>
            Una vez realizada tu compra te brindamos 3 meses de garantía directa por parte de MOONS , desde que el producto llega a tus manos.
          </p>
        </section>

        <h2 className='font-bold text-xl'>
          ¿Qué cubre durante 3 meses nuestra garantía oficial?
        </h2>
        <section className='text-lg'>
          <p className='my-4'>
            Cubre defectos de fábrica del producto.
          </p>

          <p className='my-4'>
            No cubrimos roturas fisicas o incidentes ajenos a nuestra voluntad, por caídas o utilización innadecuada del producto.
          </p>
        </section>

        <h2 className='font-bold text-xl'>
          ¿Cómo procedo si el equipo falla por defectos cubiertos en la garantía?
        </h2>
        <section className='text-lg'>
          <p className='my-4'>
            Durante los primeros 3 días hábiles de recibido el producto, el cliente puede solicitar la devolución del dinero (por el mismo medio de pago recibido), o solicitar el reemplazo por otro producto igual, o de mayor valor (diferencia de valor a cargo del cliente).
          </p>

          <p className='my-4'>
            En este lapso de tiempo, en MOONS asumimos los costos de envío y reenvío del producto.
          </p>

          <p className='my-4'>
            Pasado los 3 días hábiles de recibido el producto, los costo de envío estarán a cargo del cliente.
          </p>
        </section>

        <h2 className='font-bold text-xl'>
          ¿Cuánto tiempo demora la verificación del equipo en garantía?
        </h2>
        <section className='text-lg'>
          <p className='my-4'>
            La verificación y aprobación del departamento de control de calidad de la empresa, demora 5/7 días hábiles, desde recibido por MOONS.
          </p>

          <p className='my-4'>
            El envío de un nuevo producto o devolución del dinero, se realiza durante los 3/5 días hábiles subsiguientes.
          </p>
        </section>

        <h2 className='font-bold text-xl'>
          ¿Qué pasa si el producto no coincide con las prestaciones esperadas?
        </h2>

        <section className='text-lg'>
          <p className='my-4'>
            Durante los primeros 3 días hábiles de recibido el producto, el cliente puede solicitar el cambio por cualquier otro producto MOONS de igual o mayor valor (diferencia de valor y costos de envío, a cargo del cliente)
          </p>
        </section>

        <h2 className='font-bold text-xl'>
          Requisitos generales de garantía:
        </h2>
        <section className='text-lg'>
          <ul className='list-disc p-5'>
            <li className=''>
              Todas las solicitudes deben ser realizadas por Whastapp al número <a href='https://api.whatsapp.com/send?phone=5492216364212&text=Hola!%20Visite%20la%20web%20y%20quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20los%20productos.' className='underline text-blue-600 hover:font-semibold'>2216364212</a> de Lunes a Viernes de 10 a 17hs.
            </li>
            <li className='my-4'>
              Se deberá conservar y remitir junto al producto, el packaging original y todo el contenido en perfecto estado.
            </li>
            <li className='mt-4'>
              El valor considerado para la garantía del producto, es el abonado por el cliente en su orden de compra (en el caso que esté allá modificado su precio de lista o promocional).
            </li>
          </ul>
        </section>
      </article>

      <Footer />
      <Wsp />
    </>
  )
}