import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore'
import back from "../../images/back.svg"
import firebaseApp from '../../firebase'
import Swal from 'sweetalert2'
const firestore = getFirestore(firebaseApp)

export default function Reloj() {
    const [watch, setWatch] = useState(null)
    const [form, setForm] = useState({
        precio: ''
    })
    const { id } = useParams()

    const getWatchById = async (id) => {
        const docRef = doc(firestore, `/relojes/${id}`)
        const orderDoc = await getDoc(docRef)
        const data = orderDoc.data()
        return data
    }

    const handleOnChange = (e) => {
        const { name, value } = e.target

        setForm(prevForm => {
            return {
                ...prevForm,
                [name]: value
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const docRef = doc(firestore, `/relojes/${id}`)
        await updateDoc(docRef, {precio: parseInt(form.precio)})

        Swal.fire({
            title: "Producto actualizado",
            icon: "success",
            timer: 2500,
            timerProgressBar: "true",
            confirmButtonColor: "#18181b",
            iconColor: "#18181b",
            reverseButtons: true,
        })
    }


    useEffect(() => {
        const fetchWatch = async () => {
            const response = await getWatchById(id)
            setWatch(response)
            setForm({ precio: response.precio })
        }

        fetchWatch()
    }, [])

    return (
        <>
            {watch &&
                <main className='h-[100vh]'>
                    <Link to='/admin/relojes'>
                        <img src={back}></img>
                    </Link>
                    <article>
                        <img className='m-auto sm:w-[30vw]' src={watch.imagen}></img>
                        <h1 className='text-center font-bold text-3xl'>
                            {watch.nombre}
                        </h1>
                    </article>

                    <form className='p-4 flex flex-col' onSubmit={handleSubmit}>
                        <label className='text-3xl'>Precio:</label>
                        <input onChange={handleOnChange} type="number" name="precio" value={form.precio} className='border-2 p-3 text-xl' />
                        <input className="bg-zinc-900 h-12 w-full text-white rounded-md mt-36" type='submit' value='ACTUALIZAR' />
                    </form>
                </main>
            }
        </>
    )
}
