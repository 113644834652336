import React from 'react'
import Footer from './Footer'
import Header from './Header'
import Wsp from './Wsp'

export default function Envios({showCart, setShowCart}) {
  return (
    <>
      <Header showCart={showCart} setShowCart={setShowCart}/>
      <article className='p-4 mt-24 sm:px-40'>
        <h1 className='text-center font-bold text-3xl p-4'>
          MEDIOS DE PAGO Y ENVÍOS
        </h1>

        <h2 className='font-bold text-xl my-4'>
          ¿Qué formas de pago puedo aprovechar para realizar mi compra?
        </h2>

        <section className='text-lg'>
          <p className='mt-4'>
            Disponemos de los siguientes medios de pago:
          </p>

          <ul className='list-disc p-5'>
            <li className=''>
              Tarjetas de crédito (Mercado pago)
            </li>
            <li className='mt-4'>
              Tarjetas de débito (Mercado pago)
            </li>
          </ul>
        </section>

        <h2 className='font-bold text-xl'>
          ¿Cuál es el costo de envío?
        </h2>

        <section className='text-lg'>
          <p className='my-4'>
            ¡GRATIS! Realizamos envios SIN CARGO a TODO el país a través Correo Argentino. Que brinda un servicio SEGURO, RAPIDO y con un excelente servicio de SEGUIMIENTO y ATENCIÓN AL CLIENTE.
          </p>

          <p className='my-4'>
            También podés recibir tu pedido SIN CARGO en 24/48hs por moto/mensajería privada, consulte precios por envíos a domicilio a través de WhatsApp o e-mail.
          </p>
        </section>

        <h2 className='font-bold text-xl'>
          ¿Cuánto tiempo demora el despacho de mi smartwatch?
        </h2>

        <section className='text-lg'>
          <p className='my-4'>
            Una vez acreditado tu pago, lo corroboramos y en 48hs (hábiles) despachamos tu pedido con rapidez.
          </p>

        </section>

        <h2 className='font-bold text-xl'>
          ¿Dónde puedo ver el seguimiento del envío de mi smartwatch?
        </h2>

        <section className='text-lg'>
          <p className='my-4'>
            Podes seguir el estado de tu envío en el sitio web de CORREO ARGENTINO:
          </p>

          <p className='my-4 text-center text-blue-600 underline sm:text-left hover:font-semibold'>
            <a href='https://www.correoargentino.com.ar/seguimiento-de-envios'>
              Seguimiento de envíos
            </a>
          </p>
        </section>

      </article>
      <Footer />
      <Wsp />
    </>
  )
}
