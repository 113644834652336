import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import back from "../../images/back.svg"

const Cart = ({ set }) => {
    const [products, setProducts] = useState([])

    useEffect(() => {
        if (localStorage.getItem('cart')) {
            const data = JSON.parse(localStorage.getItem('cart'))
            setProducts(data)
        } else {
            return
        }
    }, [])

    const total = () => {
        return products.reduce((acc, el) => {
            if (el.cantidad > 50) {
                return (acc + (el.precio - (el.precio * el.descuentoC / 100)) * el.cantidad)
            } else if (el.cantidad >= 10 && el.cantidad <= 49) {
                return (acc + (el.precio - (el.precio * el.descuentoB / 100)) * el.cantidad)
            } else if (el.cantidad >= 5 && el.cantidad < 10) {
                return (acc + (el.precio - (el.precio * el.descuentoA / 100)) * el.cantidad)
            } else {
                return (acc + el.precio * el.cantidad)
            }
        }, 0)
    }
    
    const addQuantity = (id) => {
        setProducts(prevProducts => {
            let [filteredProd] = prevProducts.filter(prod => prod.nombre == id)
            filteredProd.cantidad += 1
            localStorage.setItem('cart', JSON.stringify(products))
            return [...prevProducts]
        })
    }

    const subQuantity = (id) => {
        setProducts(prevProducts => {
            let filteredProd = prevProducts.filter(prod => prod.nombre == id)
            if (filteredProd[0]['cantidad'] == 1) {
                return [...prevProducts]
            } else {
                filteredProd[0]['cantidad'] -= 1
                localStorage.setItem('cart', JSON.stringify(products))
                return [...prevProducts]
            }
        })
    }

    const removeFromCart = (id) => {
        setProducts(prevProducts => {
            let filteredProd = prevProducts.filter(prod => prod.nombre != id)
            localStorage.setItem('cart', JSON.stringify(filteredProd))
            return filteredProd
        })
    }

    return (
        <div id="modal-component-container" className='fixed inset-0' >
            <div className="
                modal-flex-container
                flex
                items-end
                justify-center
                min-h-screen
                pt-4
                pb-20
                text-center
                px-6
                sm:px-52
                "
            >
                <div className="
                    modal-bg-container
                    fixed inset-0 
                    bg-gray-700
                    bg-opacity-75
                    "
                >
                </div>

                <div className="
                    modal-container
                    inline-block
                    bg-white
                    rounded-lg
                    text-left
                    shadow-xl
                    transform
                    w-full h-[95vh]
                    overflow-y-auto
                    "
                >

                    <div className="
                        modal-wrapper
                        bg-white
                        p-2
                        "
                    >
                        <div className="modal-wrapper-flex">
                            <div className="modal-content h-[72vh] overflow-scroll shadow-[0_10px_10px_-12px_rgba(0,0,0,0.3)]">
                                <article className="flex justify-between">
                                    <button onClick={() => set()}>
                                        <img src={back}></img>
                                    </button>
                                </article>
                                {products.map((producto) => {
                                    return (
                                        <article className="w-full flex flex-wrap mb-4 " key={producto.nombre}>
                                            <div className="flex">
                                                <img className="w-[25vw] sm:w-[10vw]" src={producto.imagen} />
                                                <section className="px-4 flex flex-col justify-center w-[60vw]">
                                                    <h2 className='font-bold'>{producto.nombre}</h2>
                                                    <p>${producto.precio}</p>
                                                </section>
                                                <button className=" rounded-md bg-zinc-900 w-4 h-6 text-white text-center" onClick={() => removeFromCart(producto.nombre)}>
                                                    x
                                                </button>
                                            </div>
                                            <aside className="w-full px-4 flex flex-col justify-center">
                                                <p className="text-center">
                                                    Cantidad:
                                                </p>
                                                <div className="flex justify-between items-center">
                                                    <button
                                                        className="rounded-lg bg-zinc-900 h-10 w-10 text-white text-3xl"
                                                        onClick={() => { subQuantity(producto.nombre) }}
                                                    >
                                                        -
                                                    </button>

                                                    <p>
                                                        {producto.cantidad}
                                                    </p>

                                                    <button
                                                        className="rounded-lg bg-zinc-900 h-10 w-10 text-white text-3xl"
                                                        onClick={() => addQuantity(producto.nombre)}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </aside>
                                        </article>
                                    )
                                })}


                            </div>
                        </div>
                    </div>

                    <div className="modal-actions p-4">
                        <section className="flex justify-between">
                            <h2 className="font-bold text-3xl">Total </h2>
                            <h2 className="text-2xl">${total().toFixed(2)}</h2>
                        </section>
                        {products.length ?
                            <button className="rounded-lg bg-zinc-900 h-12 w-full text-white">
                                <Link to='/checkout' className="flex justify-center items-center h-full">FINALIZAR COMPRA</Link> :
                            </button> :
                            <div className="text-center p-6 text-lg">
                                No hay productos
                            </div>
                        }


                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cart